.topBar {
    background: black;
    color: white;
    height: 70px;
    text-align: -webkit-center;
    position: fixed;
    z-index: 4000;
    font-family: "Poppins", sans-serif;
    font-size: 1.25rem;
    line-height: 1.5rem;
}

.react-select__option{
    color:blueviolet;
}

.react-select-container{
    color: black !important;
    text-align: left;
    max-width: 882px;
}